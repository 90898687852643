<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="收货人姓名" prop="name">
            <el-input clearable v-model.trim="params.name" placeholder="请输入收货人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input clearable v-model.trim="params.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button @click="addNotice()" type="primary" size="small">添加地址</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="收货人姓名">
          <template slot-scope="{row}">{{ row.name }}</template>
        </el-table-column>
        <el-table-column label="手机号" width="160">
          <template slot-scope="{row}">{{ row.mobile }}</template>
        </el-table-column>
        <el-table-column label="所在地区">
          <template slot-scope="{row}">
            <span>{{ row.province + row.city + row.county + (row.town || '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="详细地址">
          <template slot-scope="{row}">
            <span>{{ row.addr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="addNotice(row)">修改</el-button>
            <el-button size="mini" type="danger" @click="deletez(row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--修改|新增-->
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible">
      <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="收货人姓名" prop="name">
          <el-input placeholder="请输入收货人姓名" :maxlength="50" v-model="txInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input placeholder="请输入手机号" :maxlength="11" v-model="txInfo.mobile"></el-input>
        </el-form-item>
        <el-form-item label="收货地区" prop="province_id">
          <el-select @change="changeProvince('province')" size="small" class="choose-machine center"
            v-model="txInfo.province_id" placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('city')" size="small" class="choose-machine center"
            v-model="txInfo.city_id" placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('county')" size="small" class="choose-machine center"
            v-model="txInfo.county_id" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select size="small" class="choose-machine center" v-model="txInfo.town_id" placeholder="乡镇/街道"
            style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="addr">
          <el-input placeholder="请输入详细地址" :maxlength="250" v-model="txInfo.addr"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input placeholder="请输入备注" :maxlength="250" v-model="txInfo.order_remark"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="accountSubmit">确 定</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import { RegExp } from "@/../ui-utils";
import XDialog from '@/components/x-dialog/x-dialog';
import * as API_goods from "@/api/goods";
import { shopAddressList, addShopAddress, editShopAddress, delShopAddress, getShopAddress } from '@/api/shopSetting';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: 'addressManagement',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      editVisible: false,//审核页
      loadingtitle: '',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        def_addr: 1,
        name: '',
        mobile: '',
        addr: '',
        order_remark: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      },
      txRules: {//审核表单验证
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机号格式有误，请重新输入"));
              } else {
                callback();
              }
            }, required: true, trigger: 'change'
          }],
        province_id: [
          { required: true, message: '请选择收货地区', trigger: 'change' }],
        addr: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**发布状态 */
    account_type_filter: value => {
      switch (value) {
        case 0:
          return '未发布';
        case 1:
          return '已发布';
      }
    }
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    changeProvince (type) {
      if (type == "province") {
        this.txInfo.city_id = "";
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") {
          this.getAreaHandle("province");
        } else {
          this.getAreaHandle("city", { id: this.txInfo.province_id });
        }
      } else if (type == "city") {
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") return;
        if (this.txInfo.city_id !== "") this.getAreaHandle("county", { id: this.txInfo.city_id });
      } else if (type == "county") {
        this.txInfo.town_id = "";
        if (this.txInfo.city_id === "") return;
        if (this.txInfo.county_id !== "") this.getAreaHandle("town", { id: this.txInfo.county_id });
      }
    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
    /**关闭审核认证弹窗 */
    cancel () {
      this.editVisible = false//审核页
    },
    /**打开弹窗 */
    addNotice (row = null) {
      if (row) {//编辑
        getShopAddress(row.addr_id).then((response) => {
          this.loadingtitle = "修改地址"
          if (response.town_id == 0) {
            response.town_id = ''
            response.town = ''
          }
          this.txInfo = {
            ...response
          }
          this.getAreaHandle("province");
          if (this.txInfo.province_id) {
            this.getAreaHandle("city", { id: this.txInfo.province_id });
          }
          if (this.txInfo.city_id) {
            this.getAreaHandle("county", { id: this.txInfo.city_id });
          }
          if (this.txInfo.county_id) {
            this.getAreaHandle("town", { id: this.txInfo.county_id })
          }
          this.editVisible = true
        })
      } else {//新增
        this.loadingtitle = "添加地址"
        this.txInfo = {//审核表单内容
          def_addr: 1,
          name: '',
          mobile: '',
          addr: '',
          order_remark: '',
          province_id: "",
          city_id: "",
          county_id: "",
          town_id: ""
        }
        this.getAreaHandle("province");
        this.editVisible = true
      }
    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        let datas = {
          ...this.txInfo
        }

        if (valid) {
          if (datas.province_id !== "") {
            const findProvince = this.provinceList.find(item => item.id === datas.province_id)
            datas.province = findProvince.name;
            datas.jd_province_id = datas.province_id;
            datas.jd_province = datas.province;
            if (datas.city_id !== "") {
              const findCity = this.cityList.find(item => item.id === datas.city_id)
              datas.city = findCity.name;
              datas.jd_city_id = datas.city_id;
              datas.jd_city = datas.city;
            }
            if (datas.county_id !== "") {
              const findCointy = this.countyList.find(item => item.id === datas.county_id)
              datas.county = findCointy.name;
              datas.jd_county_id = datas.county_id;
              datas.jd_county = datas.county;
            }
            if (datas.town_id !== "") {
              const findTown = this.townList.find(item => item.id === datas.town_id)
              datas.town = findTown.name;
              datas.jd_town_id = datas.town_id;
              datas.jd_town = datas.town;
            } else {
              datas.town_id = 0;
              datas.jd_town_id = 0;
              datas.jd_town = 0;
            }
          }
          if (this.txInfo.addr_id) {//编辑         
            editShopAddress(datas).then((response) => {
              this.$message.success('收货地址修改成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          } else {//添加
            addShopAddress(datas).then((response) => {
              this.$message.success('收货地址添加成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          }
        }
      });
    },
    deletez (row) {//删除
      this.$confirm("确定要删除该收货地址吗?", "提示", { type: "warning" }).then(() => {
        delShopAddress(row.addr_id).then(res => {
          this.$message.success("地址删除成功");
          this.GET_AccountList();
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      shopAddressList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
